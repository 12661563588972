<template>
  <v-container v-if="vuePermissao">
    <PageToolbar voltar dark icon="assignment" :title="permissao.descricao" />

    <v-card class="px-8 pb-12 pt-8 mt-6">
      <v-form v-model="valid" ref="form" v-if="!loading">
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <v-card
                outlined
                height="40px"
                class="d-flex justify-space-between align-center"
              >
                <v-card-text class="body-1 py-0">{{
                  `Status: ${permissao.status ? "Ativo" : "Inativo"}`
                }}</v-card-text>
                <v-switch
                  class="ma-0"
                  hide-details
                  :color="permissao.status ? 'secondary' : 'success'"
                  v-model="permissao.status"
                ></v-switch>
              </v-card>
            </v-col>

            <v-col cols="12" md="8">
              <v-text-field
                v-model.lazy="permissao.componente"
                label="Component"
                :rules="[rules.required]"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="8">
              <v-text-field
                v-model="permissao.descricao"
                :label="$tc('global.descricao')"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-card
                height="40px"
                style="overflow: hidden;"
                class="pa-0"
                outlined
              >
                <v-overflow-btn
                  class="ma-0"
                  height="40px"
                  :items="permissoes"
                  :loading="loadingPermissoes"
                  :disabled="loadingPermissoes"
                  :label="$tc('permissoes.addpai')"
                  dense
                  editable
                  hide-details
                  item-text="descricao"
                  item-value="id"
                  v-model="permissao.permissao_id"
                ></v-overflow-btn>
              </v-card>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="permissao.descricao_detalhe"
                :label="$tc('global.descricaodetalhada')"
                outlined
                dense
                hide-details
              ></v-text-field>
            </v-col>

            <v-row class="px-3" align-content="stretch">
              <v-col>
                <v-card height="100%" outlined>
                  <v-subheader>ACESSOS</v-subheader>
                  <v-divider light class="mt-n2"></v-divider>
                  <v-card-text>
                    <v-checkbox
                      v-model="bitWise.menu"
                      class="mt-0"
                      :false-value="0"
                      :true-value="128"
                      label="Menu"
                      hide-details
                    ></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col>
                <v-card height="100%" outlined>
                  <v-subheader>CRUD</v-subheader>
                  <v-divider light class="mt-n2"></v-divider>
                  <v-card-text>
                    <v-checkbox
                      v-model="bitWise.visualizar"
                      class="mt-0"
                      :false-value="0"
                      :true-value="1"
                      :label="$tc('botoes.visualizar')"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="bitWise.adicionar"
                      class="mt-0"
                      :false-value="0"
                      :true-value="2"
                      :label="$tc('global.add')"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="bitWise.editar"
                      class="mt-0"
                      :false-value="0"
                      :true-value="4"
                      :label="$tc('botoes.editar')"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="bitWise.deletar"
                      class="mt-0"
                      :false-value="0"
                      :true-value="8"
                      :label="$tc('botoes.del')"
                      hide-details
                    ></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col>
                <v-card height="100%" outlined>
                  <v-subheader>{{ $tc("botoes.impressao") }}</v-subheader>
                  <v-divider light class="mt-n2"></v-divider>
                  <v-card-text>
                    <v-checkbox
                      v-model="bitWise.imprimir"
                      class="mt-0"
                      :false-value="0"
                      :true-value="16"
                      :label="$tc('botoes.imprimir')"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="bitWise.exportar"
                      class="mt-0"
                      :false-value="0"
                      :true-value="32"
                      :label="$tc('botoes.exportar')"
                      hide-details
                    ></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>

              <v-col>
                <v-card height="100%" outlined>
                  <v-subheader>{{ $tc("permissoes.customizado") }}</v-subheader>
                  <v-divider light class="mt-n2"></v-divider>
                  <v-card-text>
                    <v-checkbox
                      v-model="bitWise.limitar"
                      class="mt-0"
                      :false-value="0"
                      :true-value="64"
                      :label="$tc('botoes.limitar')"
                      hide-details
                    ></v-checkbox>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-btn
              @click.prevent="updatePermissao(id)"
              fixed
              right
              bottom
              fab
              dark
              color="buttons"
              :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
              :disabled="!valid"
            >
              <v-icon>save</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
      <Carregando v-else />
    </v-card>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { getPermissao, getPermissoes } from "@/services/api/permissoes.api.js";
import updatePermissoes from "@/mixins/configuracoes/permissoes/updatePermissoes.js";
import updateBitMask from "@/mixins/configuracoes/permissoes/updateBitMask.js";
import checkUserPermissions from "@/mixins/configuracoes/permissoes/checkUserPermissions.js";
import checkPermissions from "@/mixins/configuracoes/permissoes/checkPermissions.js";

import { mapGetters } from "vuex";

export default {
  name: "Permissao",
  mixins: [
    updatePermissoes,
    updateBitMask,
    checkUserPermissions,
    checkPermissions,
  ],
  props: ["permissaoId"],
  data: () => ({
    permissao: {
      bits: 0,
    },
    permissoes: [],
    show: false,
    valid: false,
    loading: false,
    loadingPermissoes: false,
    notFound: false,
    original: {},
    rules: {
      required: (value) => !!value || "Obrigatorio.",
      min: (v) => v.length >= 3 || "Minimo 4 caracteres",
    },
  }),
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vuePermissao() {
      return this.getAccess("vuePermissao", "visualizar");
    },
    id() {
      return this.permissaoId;
    },
    somaBits() {
      let soma =
        this.bitWise.visualizar +
        this.bitWise.adicionar +
        this.bitWise.editar +
        this.bitWise.deletar +
        this.bitWise.imprimir +
        this.bitWise.exportar +
        this.bitWise.limitar +
        this.bitWise.menu;

      return soma;
    },
  },
  watch: {},
  methods: {
    backHistory() {
      this.$router.go(-1);
    },
    fetchPermissao() {
      this.loading = true;
      this.$Progress.start();
      getPermissao(this.id)
        .then((response) => {
          this.permissao = response;
          this.original = this.lodash.cloneDeep(response);
          this.updateBitMasks(this.permissao.bits);
          this.loading = false;
          this.$Progress.finish();
          if (
            !this.$store.state.dynamic_crumb ||
            this.$store.state.dynamic_crumb != this.permissao.descricao
          ) {
            this.$store.commit("UPDATE_DYNAMICCRUMB", this.permissao.descricao);
          }
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$Progress.fail();
        });
    },
    fetchPermissoes() {
      this.loadingPermissoes = true;
      getPermissoes()
        .then((response) => {
          response.forEach((resp) => {
            this.permissoes.push(resp);
            resp.children.forEach((child) => {
              this.permissoes.push(child);
            });
          });
          this.loadingPermissoes = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingPermissoes = false;
        });
    },
  },
  mounted() {
    this.fetchPermissao();
    this.fetchPermissoes();
  },
  created() {},
};
</script>

<style scoped lang="scss"></style>
