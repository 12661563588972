import { getUsuariosPermissoes } from "@/services/api/permissoes.api.js";

export default {
  data: () => ({
    usersPermissions: [],
  }),
  methods: {
    getUsersPermissions() {
      getUsuariosPermissoes()
        .then((response) => {
          this.usersPermissions = response;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    checkUserPermissions(id, bits) {
      let isEqual = {
        visualizar: false,
        adicionar: false,
        editar: false,
        deletar: false,
        imprimir: false,
        exportar: false,
        menu: false,
      };
      this.usersPermissions.forEach((element) => {
        if (id === element.permissao_id) {
          if (
            this.checkVisualizar(bits) === false &&
            this.checkVisualizar(element.nivel_acesso)
          ) {
            isEqual.visualizar = true;
          }
          if (
            this.checkAdicionar(bits) === false &&
            this.checkAdicionar(element.nivel_acesso)
          ) {
            isEqual.adicionar = true;
          }
          if (
            this.checkEditar(bits) === false &&
            this.checkEditar(element.nivel_acesso)
          ) {
            isEqual = true;
          }
          if (
            this.checkDeletar(bits) === false &&
            this.checkDeletar(element.nivel_acesso)
          ) {
            isEqual.deletar = true;
          }
          if (
            this.checkImprimir(bits) === false &&
            this.checkImprimir(element.nivel_acesso)
          ) {
            isEqual.imprimir = true;
          }
          if (
            this.checkExportar(bits) === false &&
            this.checkExportar(element.nivel_acesso)
          ) {
            isEqual.exportar = true;
          }
          if (
            this.checkMenu(bits) === false &&
            this.checkMenu(element.nivel_acesso)
          ) {
            isEqual.menu = true;
          }
        }
      });
      return isEqual;
    },
  },
  mounted() {
    this.getUsersPermissions();
  },
};
